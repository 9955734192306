import React, { useState } from 'react'
import LoginContext from "./componet/context/login"
import TransferContext from "./componet/context/tranferContext"
import Layout from "./componet/layout/layout"



const Provider = () => {
    const [showTransfer, setShowTransfer] = useState(false)
    const [token, setToken] = useState(null)
    const url = "https://api.chartered-expressgroup.com/"
    
    // const url = "http://localhost:5000/"    
    // const url = "http://192.168.43.65:4000/"
    // const url = "http://172.20.10.2:4000/"

    const setShowTransferHandler =() => {
        setShowTransfer(!showTransfer)
    }
    
    const setTokenHandler = (payload) => {
        setToken(payload)
        // localStorage.setItem("token", payload);
    }

    return (
        <LoginContext.Provider value={{url: url, token: token, setToken: setTokenHandler }}>
        <TransferContext.Provider value={{ setShowTransfer: setShowTransferHandler, showTransfer: showTransfer}}>
          <Layout />
        </TransferContext.Provider>
        </LoginContext.Provider>
    )
}

export default Provider


// vector 76