import { nanoid } from "nanoid";
import React, { useState, useEffect, useContext } from "react";
import Cards from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";
import toast, { Toaster } from "react-hot-toast";
// import { FaCcAmazonPay, FaCcVisa } from "react-icons/fa";
import JumboHeader from "../shortcut/jumboHeader";
import CardStatus from "./card-status";
import Classes from "./card.module.css";
import Context from "../../componet/context/login";
import TranferContext from "../../componet/context/tranferContext";
import Loader from "../../componet/Loader/loader";
import Transfer from "../transfer/transfer";

export default function RequestCard(props) {
  let [nameOnCard, setnameoncard] = useState("");
  const transferCtx = useContext(TranferContext);
  const ctx = useContext(Context);
  let [ccv, setccv] = useState("");
  let [showCard, setShowcard] = useState(false);
  let [cardType, setCardType] = useState("");
  let [focus, setfocus] = useState("");
  let [selectedTab, setSelectedTab] = useState("card-builder");
  const [data, setData] = useState([]);

  const [showModal, setShowModal] = useState(false);

  const [loading, setLoading] = useState(false);


  const fetchTransactionDetail = async (ID) => {
    if (loading) return;
    setLoading(true);
    const fetching = await fetch(
      `${ctx.url}management/action/user/transaction/${ID}`,
      {
        method: "GET",
        headers: new Headers({ "Content-Type": "application/json" }),
      }
    );
    const parseBody = await fetching.json();
    setLoading(false);
    // console.log("parseBody", parseBody)
    setData(parseBody.data);
  };

  useEffect(() => {
    // console.log(props.match.params.ID);
    fetchTransactionDetail(props.match.params.ID);
  }, []);

  const onChangeValueBinder = (index, name, e) => {
    const spread = [...data];
    spread[index][name] = e.target.value;
    setData(spread);
  };

  const UpdateData = async (ID, index, e) => {
    const { date, description, account_number, transaction_type, amount } =
      data[index];

    if (loading) return;
    setLoading(true);
    const fetching = await fetch(
      `${ctx.url}management/action/user/transaction/${ID}`,
      {
        method: "POST",
        headers: new Headers({ "Content-Type": "application/json" }),
        body: JSON.stringify({
          date,
          description,
          account_number,
          transaction_type,
          amount,
        }),
      }
    );
    const parseBody = await fetching.json();
    setLoading(false);
    // console.log("parseBody", parseBody)
    fetchTransactionDetail(props.match.params.ID);
  };

  const mapData = data.map((v, i) => {
    return (
      <div key={v._id} className={Classes.main_container_fluid}>
        <input
          value={data[i].amount}
          onChange={onChangeValueBinder.bind(this, i, "amount")}
        />
        <input
          value={data[i].account_number}
          onChange={onChangeValueBinder.bind(this, i, "account_number")}
        />
        <input
          value={data[i].date}
          onChange={onChangeValueBinder.bind(this, i, "date")}
        />
        <textarea
          value={data[i].description}
          onChange={onChangeValueBinder.bind(this, i, "description")}
          rows="2"
        />
        <textarea
          value={data[i].transaction_type}
          onChange={onChangeValueBinder.bind(this, i, "transaction_type")}
          rows="2"
        />
        <div onClick={UpdateData.bind(this, v._id, i)}>Update</div>
      </div>
    );
  });

  if (loading) {
    return <Loader />;
  }

  return (
    <>
    <div className="container-fluid">
      <div
        onClick={() => setShowModal(!showModal)}
        className={Classes.Make_Transaction}
      >
        Make Transaction
      </div>

      {/* <div className={Classes.container_fluid}> */}
      <div className={Classes.container_fluid}>
        <div className={Classes.noScrollCover}>
          <div>Amount</div>
          <div>Account Number</div>
          <div>Date</div>
          <div>Description</div>
          <div>Transaction Type</div>
        </div>
        {mapData}
      </div>
    </div>
      <Transfer
      _id={props.match.params.ID}
      number={props.match.params.number}
        showModal={showModal}
        closeModal={() => setShowModal(!showModal)}
      />
    </>
  );
}
