import React, {useState} from 'react';
import {Modal} from '../../componet/reuseable/reuseable';
import Classes from './transfer.module.css';

const IntraBanking = ({showModal, closeModal}) => {
  const [msg, setMsg] = useState('');

  const onSubmitFormHandler = (e) => {
    e.preventDefault();
    setTimeout(() => {
      setMsg('');
    }, 3000);
    setMsg('Invalid Password');
  };
  return (
    <Modal showModal={showModal} closeModal={closeModal}>
      <div className={Classes.modalCover}>
        <div
          style={{visibility: msg.length > 2 ? 'visible' : 'hidden'}}
          className={Classes.msg}
        >
          {msg}
        </div>
        <form
          onSubmit={onSubmitFormHandler}
          style={{gridTemplateColumns: '1fr'}}
          className={Classes.form}
        >
          <h3 style={{margin: '7px 0px', fontSize: '13px'}}>
            Enter your token{' '}
          </h3>
          <input
            placeholder="TOKEN"
            autoFocus
            className={Classes.input}
            type="number"
            required
          />
          <h3 style={{margin: '7px 0px', fontSize: '13px'}}>
          Please contact support to ensure you have your token
          </h3>
          {/* <button disabled className={Classes.button}>
            SUBMIT
          </button> */}
        </form>
      </div>
    </Modal>
  );
};

export default IntraBanking;
