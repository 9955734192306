import React from 'react';
import Classes from "./loader.module.css"
import {ReactComponent as Loadr} from "../../asset/logo.svg"

const Loader = () => {
    return (
        <div className={Classes.loaderCover}>
            <Loadr />
            <div className={Classes.lds_ellipsis}><div></div><div></div><div></div><div></div></div>
        </div>
    )
}

export default Loader
