import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as Compliant } from '../../../asset/edit-1.svg';
import { ReactComponent as Dashboard } from '../../../asset/home.svg';
import TranferPage from '../../../pages/transfer/eventTransfer';
import Context from "../../context/login";
import TranferContext from '../../context/tranferContext';
import Classes from './nav.module.css';


/** @description NAVSECTION consisting of main nav and hero page */

const Nav = () => {
  const ctx =  useContext(Context)
  const transferCtx = useContext(TranferContext);
  const [dropDown, setDropDown] = useState(false);

  return (
    <>
      <div className={Classes.navCover}>
        <div className={Classes.nav}>
          <div className={Classes.brand}>CHARTERED ADMIN </div>
          <div style={{color: "#fff"}} className={Classes.logout} onClick={()=>window.location.replace('https://chartered-expressgroup.com')}>LOGOUT</div>
        </div>
      </div>
      <div style={{display: ctx.token ? "block" : "none"}} className={Classes.heroCover}>
        <div className={Classes.nav}>
          <div className={Classes.ownerCover}>
            <h1 style={{textTransform: "capitalize"}}>Hi BOSS!</h1>
            <div>
             
                <small>the boss himself </small>
             
            </div>
          </div>
          <div className={Classes.linkCover}>
            <Link style={{textDecoration: 'none'}} to="/dashboard">
              <EachLink svg={<Dashboard />} link_name="DASHBOARD" />
            </Link>
     
            <Link style={{textDecoration: 'none'}} to="/complaint">
              <EachLink svg={<Compliant />} link_name="LIST" />
            </Link>
            {/* <Link style={{textDecoration: 'none'}} to="/my-bank-card">
              <EachLink svg={<Card />} link_name="MY BANK CARD" />
            </Link>
            <Link style={{textDecoration: 'none'}} to="/user_profile">
              <EachLink svg={<Phone />} link_name="PROFILE" />
            </Link>
            <Link style={{textDecoration: 'none'}} to="/insight">
              <EachLink svg={<Insight />} link_name="INSIGHT" />
            </Link> */}
            {/* <a onClick={() => transferCtx.setShowTransfer()} style={{ textDecoration: "none" }} href="#"> */}
            
          </div>
          <div
            style={{display: dropDown ? 'block' : 'none'}}
            className={Classes.dropDownCover}
          >
            <div className={Classes.dropDown}>
              <ul>
                <li onClick={() => setDropDown(!dropDown)}>
                  <Link
                    to="/"
                    style={{color: 'inherit', textDecoration: 'none'}}
                  >
                    <Dashboard />
                    Dashboard
                  </Link>
                </li>
                


                {/* <li >
                  <Link
                    to="/user/bills"
                    style={{color: 'inherit', textDecoration: 'none'}}
                  >
                    <Bills />
                    Bill and payment
                  </Link>
                </li> */}
                <li onClick={() => setDropDown(!dropDown)}>
                  <Link
                    to="/complaint"
                    style={{color: 'inherit', textDecoration: 'none'}}
                  >
                  <Compliant />
                    List
                  </Link>
                </li>
                {/* <li onClick={() => setDropDown(!dropDown)}>
                  <Link
                    to="/my-bank-card"
                    style={{color: 'inherit', textDecoration: 'none'}}
                  >
                  <Card />
                  My bank card
                  </Link>
                </li>
                <li onClick={() => setDropDown(!dropDown)}>
                  <Link
                    to="/user_profile"
                    style={{color: 'inherit', textDecoration: 'none'}}
                  >
                  <Phone />
                  Profile
                  </Link>
                </li>
                <li onClick={() => setDropDown(!dropDown)}>
                  <Link
                    to="/insight"
                    style={{color: 'inherit', textDecoration: 'none'}}
                  >
                    <Insight />
                    Insight
                  </Link>
                </li> */}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <TranferPage />
    </>
  );
};

export default Nav;

export const EachLink = ({onClick, link_name, svg}) => (
  <div onClick={onClick} className={Classes.eachLinkCover}>
    <hr style={{transform: 'rotate(180deg)'}} />
    {svg}
    <small>{link_name}</small>
  </div>
);
