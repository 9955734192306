import React, { useContext, useEffect, useState } from "react";
// import TranferContext from "../../componet/context/tranferContext";
import Context from "../../componet/context/login";
import { Modal } from "../../componet/reuseable/reuseable";
import Classes from "./transfer.module.css";
import { ReactComponent as ArrowDown } from "../../asset/arrowDown.svg";
import { ReactComponent as Loading } from "../../asset/loader.svg";
import IntraBanking from "./intraBanking";

const Transfer = ({ showModal, closeModal, _id, number }) => {
  const ctx = useContext(Context);
  const [show, setShow] = useState(false);
  const [showExtraModal, setShowExtraModal] = useState(false);
  const options = ["Transfer To Same Bank", "Transfer To Other Banks"];
  const [firstNarration, setFirstNarration] = useState("");
  const [inputs, setInputs] = useState({});
  const [loading, setLoading] = useState(false);

  const submitFormTransfer = async (e) => {
    e.preventDefault()
    setLoading(true);
    const fetched = await fetch(
      `${ctx.url}admin/update/user/balance/${_id}/${firstNarration}/${number}`,
      {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/json",
        }),
        body: JSON.stringify({
          amount: inputs.amount,
          transaction_type: inputs.transaction_type,
          description: inputs.description,
        }),
      }
    );
    const data = await fetched.json();
    setLoading(false);
  };

  const setValueHandler = (name, e) => {
    e.stopPropagation();
    setFirstNarration(name);
    setInputs({...inputs, transaction_type: name})
    setShow(!show);
  };

  const closeModalHandler = () => {
    setShowExtraModal(!showExtraModal);
    const spread = [...show];
    spread[1].selected = "Transfer To Same Bank";
    setShow(spread);
  };

  const OnChangeInputType = () => {};

  return (
    <Modal showModal={showModal} closeModal={closeModal}>
      <div className={Classes.modalCover}>
        <h3>TRANSFER FUNDS</h3>
        <form onSubmit={submitFormTransfer} className={Classes.form}>
          <div onClick={() => setShow(!show)} className={Classes.selectBox}>
            <strong>
              {firstNarration.length < 1 ? "Transaction Type" : firstNarration}
            </strong>
            <ArrowDown />
            <div
              style={{ display: show ? "block" : "none" }}
              className={Classes.selectingBoxCover}
            >
              <div onClick={setValueHandler.bind(this, "DEBIT")}>DEBIT</div>
              <div onClick={setValueHandler.bind(this, "CREDIT")}>CREDIT</div>
              <div onClick={setValueHandler.bind(this, "REVERSAL")}>
                REVERSAL
              </div>
            </div>
          </div>
          <div></div>

          <input
            // value={inputs.amount}
            required
            onChange={(e) =>
              setInputs({ ...inputs, description: e.target.value })
            }
            placeholder="Description"
            value={inputs?.description}
            type="text"
            className={Classes.input}
          />
          <input
            value={inputs?.amount}
            required
            onChange={(e) => setInputs({ ...inputs, amount: e.target.value })}
            placeholder="Amount"
            type="number"
            className={Classes.input}
          />
          <input
            value={inputs.transaction_type}
            required
            onChange={(e) =>
              setInputs({ ...inputs, transaction_type: e.target.value })
            }
            placeholder="Naration ..."
            className={Classes.input}
          />
          {loading ? (
            <div
              style={{
                width: "180px",
                display: "flex",
                justifyContent: "center",
                height: "52px",
              }}
            >
              <Loading style={{ width: "52px", height: "52px" }} />{" "}
            </div>
          ) : (
            <button className={Classes.button}>MAKE TRANSFER</button>
          )}
        </form>
      </div>
      <IntraBanking showModal={showExtraModal} closeModal={closeModalHandler} />
    </Modal>
  );
};

export default Transfer;

// select account
// transfer to
// intra account, extra account
// naration
// amount
// account number to send to
// after the account, it fetches the account fullname
