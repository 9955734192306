import React from 'react'
import './App.css';
import {BrowserRouter as Router} from "react-router-dom"
import Provider from "./provider"

function App() {
  return (
    <Router>
      <Provider />
    </Router>
  );
}

export default App;
