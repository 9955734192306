import React from 'react'
import Body from './body/body'
import Nav from './nav/nav'

const layout = () => {
    return (
        <div>
            <Nav />
            <Body />
        </div>
    )
}

export default layout
