import decoder from 'jwt-decode';
import moment from 'moment';
import React, {useEffect, useState} from 'react';
import {toast, Toaster} from 'react-hot-toast';
// import { merciImage } from "../../images/merci";
export default function JumboHeader() {
  let [user, setUser] = useState({});

  // useEffect(() => {
  //   ToastAccountSuccess();
  //   // remove sidebar
  //   document.body.classList.remove('open-side-bar');
  //   let token = localStorage.getItem('token');
  //   if(!token) return window.location.replace('https://al-rayan.uk')
  //   let details = decoder(token);
  //   setUser(details);
  // },[]);

  // if the user account is activated then toast
  const ToastAccountSuccess = (e) =>
    toast.success('Account activation successful', {
      position: 'bottom-right',
      style: {
        backgroundColor: '#198754',
        color: '#fff',
      },
    });

  return (
    <div className="container-fluid">
      <Toaster />
      <div className="card mb-3" style={{maxWidth: '100%'}}>
        <div className="row">
          {/* <div className="col-md-3">
      <img src={merciImage} className="img-fluid rounded-start" alt="..."/>
    </div> */}
          <div className="col-md-4">
            <div className="card-body">
              <h5 className="card-title">{user.legal_name}</h5>
              <p className="card-text">
                Savings Account{' '}
                <span className="text-muted badge bg-warning">
                  {user.act_number}
                </span>{' '}
              </p>
              <p className="card-text">
                <small className="text-muted">{moment().format('LLL')}</small>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
