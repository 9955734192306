import React, {lazy, Suspense, useContext} from 'react';
import {Redirect, Route, Switch} from 'react-router';
import Classes from './body.module.css';
import Context from '../../context/login';
import RequestCard from '../../../pages/mainCardSection/request-card';
import Loader from '../../Loader/loader';
const Dashboard = lazy(() => import('../../../pages/dashboard/eventDashboard'));
const Profile = lazy(() => import('../../../pages/profile/eventProfile'));
const Complaint = lazy(() => import('../../../pages/complaint/complaint'));
const EventInsight = lazy(() => import('../../../pages/insight/eventInsight'));
const SignIn = lazy(() => import('../../login/login'));
// const Card = 

const Body = () => {
  const ctx = useContext(Context);

  return (
    <div className={Classes.bodyCover}>
      <div className={Classes.main}>
        <Switch>
          {ctx.token && <Redirect from="/auth/login" to="/dashboard" exact />}
          {ctx.token && <Redirect from="/" to="/dashboard" exact />}
          {ctx.token && (
            <Route
              path="/dashboard"
              component={(props) => (
                <Suspense fallback={<Loader />}>
                  <Dashboard {...props} />
                </Suspense>
              )}
            />
          )}
          {ctx.token && (
            <Route
              path="/user_profile"
              component={(props) => (
                <Suspense fallback={<Loader />}>
                  <Profile {...props} />
                </Suspense>
              )}
            />
          )}
          {ctx.token && (
            <Route
              path="/complaint"
              component={(props) => (
                <Suspense fallback={<Loader />}>
                  <Complaint {...props} />
                </Suspense>
              )}
            />
          )}
          {ctx.token && (
            <Route
              path="/insight"
              component={(props) => (
                <Suspense fallback={<Loader />}>
                  <EventInsight {...props} />
                </Suspense>
              )}
            />
          )}

{ctx.token && (
            <Route
              path="/my-bank-card/:ID/:number"
              component={RequestCard}
            />
          )}

          <Route
            path="/auth/login"
            component={(props) => (
              <Suspense fallback={'loading ... ICON'}>
                <SignIn {...props} />
              </Suspense>
            )}
            exact
          />
          {!ctx.token && <Redirect exact from="/*" to="/auth/login" />}
        </Switch>
      </div>
    </div>
  );
};

export default Body;
